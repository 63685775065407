<template>
    <div class="page-content">
        <div class="row">
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/hero_mobile.mp4" />
            <div>
                <h2>How it started</h2>
                <p>It was early 2020. We all had just gone into lockdown. Since I couldn't leave the house I decided to order some stuff online. I forgot how slow and tedious the process was.</p>
                <p>In my other tab was another site. This site had <a href="https://intercom.com" target="_blank">Intercom</a>. "Why aren't all shopping carts like intercom?" I thought to myself  "Easy to use, on the same page, & installs with just a single script." I immediately began to build a prototype.</p>
                <link-button inline href="https:demos.joeltankard.com/tiny-cart">View prototype</link-button>
            </div>
        </div>
        
        <!-- <div>
            <h2>Ordering online sucks</h2>
                <p class="mb-8">This is the typical process:
                    <ol>
                        <li>Go to a store, start scrolling through products.</li>
                        <li>"Enter your email & Get 10% off your order" pop-up mid scroll.</li>
                        <li>Adds a product to cart. Get's taken to "your cart" page. Nice, just lost my place.</li>
                        <li>Ok, time to checkout! Wait, where's my coupon? You need my email again?</li>
                        <li>Cool, love the lack of auto-complete on my mailing address.</li>
                        <li>Do I want to join your newsletter? No.</li>
                        <li>Ah, no Apple pay... let me go find my wallet.</li>
                        <li>Delivery is HOW expensive? Never mind.</li>
                    </ol>
                </p>
        </div> -->
        <div id="logo" class="row row-reverse">
            <img src="https://cdn.joeltankard.com/website/projects/tiny-cart/logo-scale.png" />
            <div>
                <h2>Logo</h2>
                <p>I wanted the logo on every Tiny Cart button. This way when visiting a shop users would begin to recognise the cart icon. However, I did not want to take away from the style of the site, so it had to be subtle. It made sense for the logo to just be a cart. Removing all the unnecessary elements of a cart allowed the logo to work on any background, with any contrast, and most importantly at any scale. Finally, to make it stand out I added a smiley face.  </p>
            </div>
            <img src="https://cdn.joeltankard.com/website/projects/tiny-cart/logo-contrast.png" />
            <img src="https://cdn.joeltankard.com/website/projects/tiny-cart/logo.png" />
       </div>

       <div id="ui" class="row row-reverse">
           <div>
               <h2>UI and UX</h2>
               <p>The UI was heavily influenced by Intercom. They had already worked out how to provide visitors with a great experience, without an intrusive UI.  So, why re-invent the wheel? </p>
               <p>I wanted it to be stupidly easy to install. A single script and buttons with the products IDs attached to an attribute tag. That's it. That's all a developer needed to do to create a fully functioning store. </p>
                <p>    <span class="p-4 rounded mb-0 bg-gray-100 inline-block text-xs whitespace-no-wrap overflow-scroll w-full">
                        <code>&lt;script store-id="<span class="p-1 rounded bg-blue-200 text-blue-800">Store ID</span>" src="https://init.tinycart.co/"&gt;&lt;/script&gt;</code>
                    </span>
                    <span class="p-4 rounded  bg-gray-100 inline-block text-xs whitespace-no-wrap overflow-scroll w-full">
                        <code>&lt;button product-id="<span class="p-1 rounded bg-blue-200 text-blue-800">Product ID</span>"&gt;Buy button&lt;/button&gt;</code>
                    </span></p>
          </div>
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/prototype_deleting.mp4"/>
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/prototype_opening.mp4" />
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/prototype_adding.mp4" />
       </div>

       <div id="website" class="row">
           <div>
               <h2>Website & Motion graphics</h2>
               <p>Concepts are always explained better with images. They're explained even better with videos. I illustrated and animated simple UI interactions to get the concept across. However, having 4 videos load on a website really slows things down... after tweaking the way the site loads & compressing the videos I got the <a href="https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Ftinycart.co%2F" target="_blank">Page Speed score</a> to <b>89</b> for mobile & <b>99</b> for desktop.</p>
           </div>
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/shopping.mp4"/>
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/popups.mp4" />
            <pre-video src="https://cdn.joeltankard.com/website/projects/tiny-cart/forms.mp4" />
       </div>

        <div class="outcome">
                            <h2>Outcome</h2>
                            <div class="stats">
                            <div>
                                <h3>Life span</h3>
                                <h4>2 months</h4>
                            </div>
                            <div>
                                <h3>Staus</h3>
                                <h4>Never launched</h4>
                            </div>
                        </div>
                            <div>
                                <h3>Summary</h3>
                                <p>Initially, Tiny Cart appeared to be a straightforward project. However, as I delved deeper, I realized the complexity of building an e-commerce tool was overwhelming and beyond my capacity at the time. Consequently, I made the tough decision not to launch it. Despite not bringing it to market, the project was an enjoyable learning experience, especially about the limitations of iFrames and their security aspects. My friends encouraged me to open-source the code, but I decided to retain it. Although I still hold a fondness for the idea, the lack of a justifiable market led me to put it aside.</p>
                            </div>
                        </div>
    </div>
</template>

<script>
import PreVideo from '@/components/PreVideo';
import LinkButton from '@/components/LinkButton'

export default {
    name: 'tinyCart',
    components: {
        PreVideo,
        LinkButton
    }
}
</script>